import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppService } from '../../../core/services/app.service';
import { CookieTypesEnum } from '../../enums/cookie-types.enum';
import { CookieSettingsModel } from '../../models/cookieSettings.model';

@Component({
    selector: 'app-cookie-dialog',
    templateUrl: './cookie-dialog.component.html',
    styleUrls: ['./cookie-dialog.component.scss']
})
export class CookieDialogComponent implements OnInit {

    cookieSettings: CookieSettingsModel;
    isAdvancedSettingsView = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: { cookieTypes: CookieTypesEnum[] },
                private dialogRef: MatDialogRef<CookieDialogComponent>, private appService: AppService) {
        this.cookieSettings = this.appService.getCookiesAllowed();
        if (!this.cookieSettings) {
            this.cookieSettings = new CookieSettingsModel();
        }
    }

    ngOnInit(): void {
        this.dialogRef.disableClose = true;
    }

    checkCookieType(cookieType: string) {
        if (this.data.cookieTypes && this.data.cookieTypes.length > 0) {
            return this.data.cookieTypes.find(t => t === cookieType);
        } else {
            return false;
        }
    }

    allowCookies() {
        this.appService.allowCookies(this.cookieSettings);
        this.appService.reprocessCookie();
        this.dialogRef.close();
    }

    allowOnlyNecessaryCookies() {
        this.cookieSettings = new CookieSettingsModel();
        this.appService.allowCookies(this.cookieSettings);
        this.appService.reprocessCookie();
        this.dialogRef.close();
    }

    allowAllCookies() {
        const cookieSettings = new CookieSettingsModel();
        cookieSettings.allowAllCookies();
        this.appService.allowCookies(cookieSettings);
        this.appService.reprocessCookie();
        this.dialogRef.close();
    }

    changeCookieView() {
        this.isAdvancedSettingsView = true;
    }

}
