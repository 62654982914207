/* src/app/shared/components/cookie-dialog/cookie-dialog.component.scss */
::ng-deep .mat-mdc-dialog-container .mdc-dialog__surface {
  padding: 20px 30px !important;
}
::ng-deep .mdc-dialog__container {
  height: auto !important;
  max-height: 85vh !important;
}
.internal-section-title {
  font-size: 12px;
  font-weight: 500;
  color: #2f79d5;
  text-transform: uppercase;
  margin-top: 12px;
  margin-left: 5px;
  display: table;
}
.page-title {
  font-size: 18px;
  color: #265ca0;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.btn {
  font-size: 12px;
}
.btn-primary {
  background: #265ca0;
  transition: background 0.3s ease-in-out;
  margin: 0;
}
.btn-primary:hover {
  background: #1f46bc;
}
.color-orange {
  color: #E9B21B !important;
}
.accept-cookie {
  border-color: #AFB9C3 !important;
  border-style: solid !important;
  color: #AFB9C3;
  border-width: thin !important;
  background: white;
  transition: background 0.3s ease-in-out;
}
.accept-cookie:hover {
  background: #AFB9C3 !important;
  color: white !important;
}
.disabled ::ng-deep .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #265ca0;
}
.card-title {
  margin-bottom: 0;
}
::ng-deep .mat-checkbox-inner-container {
  width: 17px !important;
  height: 17px !important;
}
::ng-deep .mat-checkbox-layout {
  margin-bottom: 0 !important;
}
.description {
  font-size: 14px;
}
.btn-primary,
.btn-success {
  font-size: 10px !important;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 10px 15px;
  margin: 0 auto;
  display: table;
  border: none;
  border-radius: 4px;
}
.card-title {
  margin-left: 1%;
  cursor: pointer;
}
.cookies-dialog-action {
  margin: 20px 15px;
}
@media (max-width: 1366px) and (max-height: 768px) {
  .cookies-dialog .page-title {
    font-size: 20px;
  }
  .cookies-dialog .description {
    font-size: 14px;
  }
  .cookies-dialog .internal-section-title {
    font-size: 12px !important;
  }
  .cookies-dialog-action {
    flex-direction: column-reverse;
    margin: 10px 15px;
  }
  .btn-primary {
    margin: 10px 0 0 0 !important;
  }
  .outline-text {
    margin: 0 !important;
  }
}
/*# sourceMappingURL=cookie-dialog.component-MA36SQNB.css.map */
