<div class="d-flex" *ngIf="isAllowedEnv()">
    <ng-select class="e-select lang-switcher"
               appendTo="body"
               [items]="languages"
               [closeOnSelect]="true"
               [clearable]="false"
               [searchable]="false"
               [(ngModel)]="currentLanguage"
               (change)="changeLanguage($event)">
        <ng-template ng-label-tmp let-item="item">
            <div class="d-flex">
                <img alt="Lang Switcher" class="my-auto mr-2 lang-switcher-image" width="18" height="18"
                     src="../../../../assets/images/language-switcher.svg"/>
                <span class="lang-switcher-label" *ngIf="!isMobile">
                    {{ (getLabel(item) | translate)?.toUpperCase() }}
                </span>
            </div>
        </ng-template>

        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
        <span class="lang-switcher-label">
                {{ (('languages.' + item) | translate).toUpperCase() }}
        </span>
        </ng-template>
    </ng-select>
</div>

