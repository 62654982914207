<div class="container cookies-dialog">

    <h1 class="page-title mt-2" translate>
        cookies.dialog.title</h1>
    <p class="description mb-5" [innerHTML]="'cookies.dialog.text-1' | translate"></p>

    <div class="card">
        <div class="row card-title py-1">
            <div class="col-1 align-self-center no-padding">
                <mat-checkbox class="mr-4 cookie-checkbox"
                              id="cookie-settings-allow-necessary"
                              [(ngModel)]="cookieSettings.allowNecessary"
                              [checked]="true"
                              [disabled]="true">
                </mat-checkbox>
            </div>
            <div class="internal-section-title col-9 no-padding" data-toggle="collapse" href="#collapseNecessaryDescription" translate>cookies.dialog.necessary.title</div>
        </div>
        <div class="description collapse card-body" id="collapseNecessaryDescription" translate>
            cookies.dialog.necessary.description
        </div>
    </div>

    <div class="card" *ngIf="checkCookieType('ANALYTICS')">
        <div class="row card-title py-1">
            <div class="col-1 align-self-center no-padding">
                <mat-checkbox class="mr-4"
                              id="cookie-settings-allow-analytics"
                              [(ngModel)]="cookieSettings.allowAnalytics"
                              [color]="'primary'">
                </mat-checkbox>
            </div>
            <div class="internal-section-title col no-padding" data-toggle="collapse" href="#collapseAnalyticsDescription"
                 translate>cookies.dialog.analytics.title
            </div>
        </div>
        <div class="description collapse card-body" id="collapseAnalyticsDescription" translate>
            cookies.dialog.analytics.description
        </div>
    </div>

    <div class="card" *ngIf="checkCookieType('MARKETING')">
        <div class="row card-title py-1">
            <div class="col-1 align-self-center no-padding">
                <mat-checkbox id="cookie-settings-allow-marketing"
                              class="mr-4"
                              [(ngModel)]="cookieSettings.allowMarketing"
                              [color]="'primary'">
                </mat-checkbox>
                <div class="internal-section-title col no-padding" data-toggle="collapse" href="#collapseMarketingDescription"
                     translate>cookies.dialog.marketing.title
                </div>
            </div>
        </div>
        <div class="description collapse card-body" id="collapseMarketingDescription" translate>
            cookies.dialog.marketing.description
        </div>
    </div>
    <div class="card" *ngIf="checkCookieType('PREFERENCES')">

        <div class="row card-title py-1">
            <div class="col-1 align-self-center no-padding">
                <mat-checkbox class="mr-4"
                              id="cookie-settings-allow-preferences"
                              [(ngModel)]="cookieSettings.allowPreferences"
                              [color]="'primary'">
                </mat-checkbox>
            </div>
            <div class="internal-section-title col no-padding" data-toggle="collapse" href="#collapsePreferencesDescription"
                 translate>cookies.dialog.preferences.title
            </div>
        </div>
        <div class="description collapse card-body" id="collapsePreferencesDescription" translate>
            cookies.dialog.preferences.description
        </div>
    </div>
</div>

<div class="row cookies-dialog-action">
    <button class="col btn btn-primary accept-cookie mr-2" id="btn-allow-cookies" (click)="allowCookies()"
            translate>
        cookies.dialog.approve
    </button>
    <button class="col btn btn-primary ml-2 color-orange" id="btn-allow-all-cookies" (click)="allowAllCookies()"
            translate>
        cookies.dialog.approve-all
    </button>
</div>

