import { Component, OnInit } from '@angular/core';
import { LANG_DE, LANG_EN } from 'src/app/app.constants';
import { BaseComponent } from 'src/app/core/abstractions/base.component';
import { LanguageService } from 'src/app/core/services/language.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-lang-switcher',
    templateUrl: './lang-switcher.component.html',
    styleUrls: ['./lang-switcher.component.scss'],
})
export class LangSwitcherComponent extends BaseComponent implements OnInit {
    currentLanguage: string;
    languages = [LANG_DE, LANG_EN];
    isMobile = false;

    constructor(private languageService: LanguageService) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        this.updateCurrentLanguageWithLoggedUserOrLocalCurrentLanguage();
        this.languageService.languageChangeEmitter.subscribe(() => {
            this.updateCurrentLanguageWithLoggedUserOrLocalCurrentLanguage();
        });
    }

    updateCurrentLanguageWithLoggedUserOrLocalCurrentLanguage() {
        this.currentLanguage =
            this.userService.loggedUser?.lang ||
            this.languageService.getLocalCurrentLanguage();
    }

    changeLanguage(language) {
        if (this.isAllowedEnv()) {
            this.userService
                .updateLanguage(language)
                .then(() => {
                    this.languageService.changeLanguage(language);
                })
                .catch(() => {
                    this.languageService.changeLanguage(language);
                    this.currentLanguage = language;
                });
        }
    }


    isAllowedEnv() {
        return environment.env === 'test' || environment.env === 'dev';
    }

    hasSmallScreenWidth() {
        return window.outerWidth <= 820;
    }

    getLabel(item) {
        return this.hasSmallScreenWidth() ? item : `languages.${item}`;
    }
}
