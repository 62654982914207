/* src/app/shared/components/lang-switcher/lang-switcher.component.scss */
.lang-switcher img {
  filter: brightness(0) invert(1) grayscale(1) brightness(2);
}
.lang-switcher ::ng-deep .ng-select-container {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: white;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}
.lang-switcher ::ng-deep .ng-select-container:hover,
.lang-switcher ::ng-deep .ng-select-container:focus {
  box-shadow: none;
}
.lang-switcher ::ng-deep .ng-option {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  text-align: center;
}
.lang-switcher ::ng-deep .ng-value {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
}
.lang-switcher ::ng-deep .ng-value .lang-switcher-label {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  margin: auto;
}
.lang-switcher ::ng-deep .ng-arrow-wrapper {
  margin-left: 5px;
}
.lang-switcher ::ng-deep .ng-arrow-wrapper .ng-arrow {
  border-color: white transparent transparent;
}
@media only screen and (max-height: 900px) {
  .lang-switcher .ng-value {
    font-size: 12px;
  }
  .lang-switcher .ng-value .lang-switcher-label {
    font-size: 12px;
  }
}
@media only screen and (max-height: 900px) {
  .lang-switcher-label {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 500;
  }
}
@media (max-width: 500px) {
  ::ng-deep ng-select .ng-arrow-wrapper {
    opacity: 0;
  }
  ::ng-deep .ng-dropdown-panel {
    min-width: 90px !important;
  }
}
/*# sourceMappingURL=lang-switcher.component-X3QKEQNE.css.map */
